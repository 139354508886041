

import { env } from "../../env";


export let  audioReceive ={
  
    init: function (data) {
      this.data =null
      this.data = data;
      console.log(this.data,'entry>>>')
        this.cacheDOM();
        this.render();
    
      },
      cacheDOM: function () {
        this.$message = $("#message-module");
        this.$parentElem = this.$message.find(".message-section").find("ul");
      },
      scrollBottom: function () {
        $(".messages").animate(
          {
            scrollTop: $("#message-module")[0].scrollHeight,
          },
          "fast"
        );
      },
      
      render: function(){
        const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-color').trim();

        if(this.data.hasOwnProperty("text")){

          if (this.$parentElem.find('audio').length > 0) {
            let previousAudios = this.$parentElem.find('audio');
            let previousAudio = previousAudios[previousAudios.length-1];
            console.log(previousAudio, "prevaudio>>>>")
            previousAudio.pause();
          }
       
    
          console.log(this.data.path, "audioPath::::")
          let audioSrc = `${this.data.path}?t=${new Date().getTime()}`;

          let node = $(`<li class="sent"><img id="sentImage" src="${env.Orgimg}" alt="">
              <div class="connected">
                  <svg height="22px" fill="${secondaryColor}" id="svg2" viewBox="0 0 32 32" width="22px" xmlns="http://www.w3.org/2000/svg">
                      <path d="M30,22L16.001,8L2.001,22Z"></path>
                  </svg>
                  <div id="utterPara" style="margin-bottom:5px;">${this.data.text}
                      <audio autoplay controls src="${audioSrc}" style="width: 100%; margin: unset; border-radius: 22px; margin-top:10px"></audio>
                  </div>
              </div>
          </li>`);

node.appendTo(this.$parentElem);

let audioElements =$('.sent').find('audio');
audioElements.not(':last').remove();

                       
        }
      
        this.scrollBottom();
      }
}