import { Visitor } from "./visitorIdGenerator";
import { env } from "../env";
import { playSound } from "./notificationGreet";
import { getUserToken } from "./subscriptionNotification";
import { cookie } from "../js/general/cookie";
import { googleModule } from "../js/oAuth/googleModule";
import { sessionstorage } from "../js/general/sessionstorage";

class BotEmbeded {
    constructor() {
        this.showBot = false;
        this.botLoaded = false;
        this.baseUrl = env.protocol === 'https' ? `${env.protocol}://${env.server}` : `${env.protocol}://${env.server}:${env.port}`;
        this.isMobileDevice = () => /(iphone|ipod|ipad|android|blackberry|windows phone)/i.test(navigator.userAgent);

        Element.prototype.setAttributesiframe = function (attrs) {
            for (let idx in attrs) {
                if ((idx === 'styles' || idx === 'style') && typeof attrs[idx] === 'object') {
                    for (let prop in attrs[idx]) {
                        this.style[prop] = attrs[idx][prop];
                    }
                } else if (idx === 'html') {
                    this.innerHTML = attrs[idx];
                } else {
                    this.setAttribute(idx, attrs[idx]);
                }
            }
        };
    }

    init(botStyle) {
        this.bindIframe(botStyle);
        this.cacheDOM();
        let script = document.getElementById("embedScript");
        console.log(script, "scripppsss>>>");
        let organizationData = script.getAttribute("bot");
        let forTesting = script.getAttribute("test") || false;
        this.visitor = new Visitor(this.$palmBot, this.baseUrl, this.appendButtonWebpage.bind(this), this, organizationData, forTesting);
        let loadBotStatus = this.visitor.loadBot();
        if (!loadBotStatus) return;

        this.render();
        console.log(this.iframeprop, "ifp embed");
        this.visitor.sendEventToBot(this.iframeprop, this.$d1);
        let search = window.location.search;
        let urlp = new URLSearchParams(search);
        let query = urlp.get("keys") || "";
        if (query && query.length > 0) {
            this.roundBtnClicked();
        }
    }

    appendButtonWebpage() {
        let webButton = document.getElementById("webButton");
        if (webButton) webButton.remove();

        let button = document.getElementsByClassName("elementor-button-wrapper")[4] || document.getElementsByClassName("elementor-button-wrapper")[3];
        let addButton = document.createElement("button");
        addButton.id = "webButton";
        addButton.className = "elementor-button elementor-button-link elementor-size-lg";
        addButton.style.border = "unset";
        addButton.innerHTML = `<span class="elementor-button-content-wrapper"><span class="elementor-button-text">Talk to Live Agent</span></span>`;
        button.appendChild(addButton);

        if (!this.showBot) {
            addButton.addEventListener("click", async (event) => {
                try {
                    let visitorId = cookie.getCookie('webUniqueID') || cookie.getCookie('uniqueID');
                    let URL = `${env.protocol}://${env.server}:${env.port}${env.basePath}pushnotification/getVisitors/suscribed`;

                    const response = await fetch(URL, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            visitor_id: visitorId
                        })
                    });

                    const visitor = await response.json();
                    const visitorData = visitor.data.clientDetails || {};
                    if (Object.keys(visitorData).length < 1) {
                        googleModule.signInWithGoogle();
                        window.addEventListener('message', async (event) => {
                            if (event.data.type === "googleAuth") {
                                this.roundBtnClicked(event, "webButtonClicked");
                            }
                        });
                    } else {
                        this.roundBtnClicked(event, "webButtonClicked");
                    }
                } catch (error) {
                    console.error(error);
                    this.roundBtnClicked(event, "webButtonClicked");
                }
                if (this.showBot) {
                    addButton.disabled = true;
                    return;
                }
            });
            addButton.disabled = false;
        } else {
            addButton.disabled = true;
        }
    }

    hideWebContent() {
        let search = window.location.search;
        if (search && search.length > 0) {
            let urlp = new URLSearchParams(search);
            let isFrame = urlp.get('botIframe') || '';
            let tagRemove = urlp.get('removeByTag') ? urlp.get('removeByTag').split(",") : '';
            let IdRemove = urlp.get('removeById') ? urlp.get('removeById').split(",") : '';
            let ClassRemove = urlp.get('removeByClassName') ? urlp.get('removeByClassName').split(",") : '';
            if (isFrame || isFrame == true) {
                for (let ar of ClassRemove) {
                    let elements = document.getElementsByClassName(ar);
                    if (elements[0]) elements[0].style.display = 'none';
                }
                for (let ar of tagRemove) {
                    let elements = document.getElementsByTagName(ar);
                    if (elements[0]) elements[0].style.display = 'none';
                }
                for (let ar of IdRemove) {
                    let element = document.getElementById(ar);
                    if (element) element.style.display = 'none';
                }
                return true;
            }
            return false;
        }
    }

    bindIframe(botStyle) {
        let webBody = document.getElementsByTagName("BODY")[0];
        let webHead = document.head;
        let webLink = document.createElement('link');
        webLink.rel = "stylesheet";
        webLink.type = "text/css";
        webLink.href = `${env.parentUrl}/embed/embed.css`;
        webHead.appendChild(webLink);

        let iframediv = document.createElement("div");
        this.iframeprop = document.createElement("iframe");
        this.iframeprop.id = "palmind";
        iframediv.appendChild(this.iframeprop);
        webBody.appendChild(iframediv);
        iframediv.id = botStyle.bot_id;
        document.getElementById("palmbot").style.display = "none";
        iframediv.setAttributesiframe(botStyle.style);
        this.iframeprop.setAttributesiframe(botStyle.allow);
        this.iframeprop.title = "Palmbot";
    }

    getBasePath() {
        return document.getElementById("palmbot").getElementsByTagName("iframe")[0].src;
    }

    cacheDOM() {
        this.$palmBot = document.getElementById("palmbot");
        this.$crossBtn = document.createElement("button");
        this.$roundBtn = document.createElement("div");
        this.$d1 = document.createElement("div");
        this.$d1.id = "d1box";
        this.$roundBtnImg = document.createElement("img");
        this.$roundBtnImgDiv = document.createElement("div");
        this.$d2 = document.createElement("div");
        this.$para = document.createElement("img");
        this.$socMediaContainer = document.createElement("div");
        this.$whatsapp = document.createElement("img");
        this.$telegram = document.createElement("img");
    }

    render() {
        this.$roundBtn.id = "round-btn";
        document.body.appendChild(this.$roundBtn);

        this.$popUpMainDiv = document.createElement("div");
        this.$popUpMainDiv.className = "popUpMainDiv";

        this.$popUpDiv = document.createElement("div");
        this.$popUpDiv.className = "popupDiv";
        this.$popUpDiv.id = "popUp";

        this.$popUpMessage = document.createElement("div");
        this.$popUpMessage.innerHTML = "Do you really want to close or continue ?";
        this.$popUpMessage.className = "popUpMessage";

        this.buttondiv = document.createElement('div');
        this.buttondiv.className = 'buttonDiv';

        this.$continueBtn = document.createElement("button");
        this.$continueBtn.innerHTML = "continue";
        this.$continueBtn.className = "continuebtn";

        this.$closeBtn = document.createElement("button");
        this.$closeBtn.className = "closebtn";
        this.$closeBtn.innerHTML = "close";

        this.$popUpDiv.appendChild(this.buttondiv);

        this.$roundBtnImgDiv.className = "roud-btn-img-div";
        this.$roundBtnImg.className = "round-btn-img";
        this.$roundBtnImg.src = `${this.baseUrl}/images/menu/bot.png`;
        this.$roundBtnImgDiv.appendChild(this.$roundBtnImg);
        this.$d1.className = "round-btn-div";
        this.$d2.className = "round-box-div";

        this.$d1.appendChild(this.$d2);
        this.$d1.appendChild(this.$roundBtnImgDiv);

        this.$socMediaContainer.id = "socMediaDiv";
        this.$whatsapp.id = "whatsappIcon";
        this.$whatsapp.src = `${this.baseUrl}/images/menu/whatsapp.png`;

        this.$telegram.id = "telegramIcon";
        this.$telegram.src = `${this.baseUrl}/images/menu/telegram.png`;
        this.$socMediaContainer.appendChild(this.$whatsapp);
        this.$socMediaContainer.appendChild(this.$telegram);

        this.$roundBtn.appendChild(this.$d1);

        this.$whatsapp.addEventListener("click", this.whatsappClicked.bind(this));
        this.$telegram.addEventListener("click", this.telegramClicked.bind(this));
        this.$d1.addEventListener("click", this.roundBtnClicked.bind(this));
        this.$crossBtn.addEventListener("click", this.closeBtnClicked.bind(this));
        this.$closeBtn.addEventListener("click", this.closeBtnClicked.bind(this));
        this.$continueBtn.addEventListener("click", this.continueBtnClicked.bind(this));

        this.$para.src = `${this.baseUrl}/images/menu/greeting-logo.png`;
        this.$para.className = "blink text_paragraph";
        this.$crossBtn.className = "cross-btn";
        this.$crossBtn.id = "hideCrossBtn";
        this.$crossBtn.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#32465ad4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>`;
        this.$palmBot.appendChild(this.$crossBtn);
    }

    mousehover() {
        document.querySelectorAll('#round-btn .text_paragraph').forEach(el => el.style.visibility = "visible");
    }

    mouseout() {
        document.querySelectorAll('#round-btn .text_paragraph').forEach(el => el.style.visibility = "hidden");
    }

    whatsappClicked() {
        let link = env.whatsappLink;
        window.open(link, '_blank');
    }

    telegramClicked() {
        let link = env.telegramLink;
        window.open(link, '_blank');
    }

    roundBtnClicked(event, type = null) {
        document.querySelectorAll('.popUpMainDiv').forEach(el => el.style.visibility = "hidden");
        document.querySelectorAll('#round-btn .text_paragraph').forEach(el => el.style.visibility = "hidden");

        async function subcribePushNotifications() {
            try {
                let visitorId = cookie.getCookie('uniqueID');
                let URL = `${env.protocol}://${env.server}:${env.port}${env.basePath}pushnotification/getVisitors/suscribed`;

                const response = await fetch(URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        visitor_id: visitorId
                    })
                });

                const visitor = await response.json();

                if (!visitor.is_register) {
                    getUserToken();
                }
            } catch (error) {
                console.error(error);
            }
        }

        async function checkNotificationPermission() {
            if ('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window) {
                try {
                    const permission = await Notification.requestPermission();
                    if (permission === 'granted') {
                        await subcribePushNotifications();
                    } else if (permission === 'denied') {
                        console.log('Permission for notifications was denied');
                    } else if (permission === 'default') {
                        console.log('Permission request was dismissed');
                    }
                } catch (error) {
                    console.error(error);
                }
            } else {
                console.log('This browser does not support notifications and firebase');
            }
        }

        checkNotificationPermission();

        if (event) {
            event.stopPropagation();
        }

        this.visitor.showBot = !this.showBot;
        this.showBot = !this.showBot;
        document.getElementById("hideCrossBtn").style.visibility = "visible";
        this.$palmBot.classList.remove("closed");
        this.$palmBot.classList.add("opened");

        this.toggleBot();
        if (!this.botLoaded) {
            try {
                let loadingImg = document.createElement("img");
                loadingImg.src = `${this.baseUrl}/images/embed/loading.gif`;
                loadingImg.className = "loading";
                loadingImg.style.position = "absolute";
                loadingImg.style.top = "40px";
                loadingImg.style.left = "50px";
                loadingImg.width = 60;
                loadingImg.height = 60;
                document.querySelector("#round-btn .popup-banner").appendChild(loadingImg);

                document.getElementById("palmbot").querySelector("iframe").onload = this.initToggle.bind(this);
                this.visitor.botLoaded = true;
                this.botLoaded = true;
                this.visitor.resetNotification();
            } catch (err) {
                console.error(err.message);
            }
        } else {
            this.toggleBot();
        }
        if (type) {
            this.visitor.webAppendButtoClicked();
        }
    }

    initToggle() {
        this.toggleBot();
    }

    toggleBot() {
        if (this.showBot) {
            this.$palmBot.classList.add("toggle");
            this.$roundBtn.classList.add("toggle");
            document.getElementById("round-btn").style.visibility = "hidden";
        } else {
            this.$palmBot.classList.remove("toggle");
            this.$roundBtn.classList.remove("toggle");
            this.$roundBtnImg.src = `${this.baseUrl}/images/menu/bot.png`;
            document.getElementById("round-btn").style.visibility = "visible";
        }
        this.appendButtonWebpage();
    }

    crossBtnClicked(event) {
        document.querySelectorAll(".popupDiv").forEach(el => el.style.visibility = "visible");
        document.querySelectorAll("#popDiv").forEach(el => el.style.visibility = "visible");
        document.querySelectorAll('.popUpMainDiv').forEach(el => el.style.visibility = "visible");

        if (event) {
            event.stopPropagation();
        }
        try {
            let loadingImg = document.querySelector("#round-btn .loading");
            if (loadingImg) {
                loadingImg.remove();
            }
        } catch (err) {
            console.log(err.message);
        }
        document.getElementById("popUp").style.display = "block";
    }

    closeBtnClicked(event) {
        if (event) {
            event.stopPropagation();
        }
        try {
            let loadingImg = document.querySelector("#round-btn .loading");
            if (loadingImg) {
                loadingImg.remove();
            }
        } catch (err) {
            console.log(err.message);
        }

        this.showBot = false;
        this.visitor.showBot = false;
        this.visitor.resetNotification();
        this.toggleBot();
        document.getElementById("popUp").style.display = "none";
        document.getElementById("hideCrossBtn").style.visibility = "hidden";
        this.$palmBot.classList.add("closed");
        this.$palmBot.classList.remove("opened");
    }

    continueBtnClicked() {
        document.querySelectorAll(".popupDiv").forEach(el => el.style.visibility = "hidden");
        document.querySelectorAll("#popDiv").forEach(el => el.style.visibility = "hidden");
        document.querySelectorAll('.popUpMainDiv').forEach(el => el.style.visibility = "hidden");

        this.toggleBot();
    }
}

let ObjEmbeded = new BotEmbeded();

function googleAnalyticsSetter() {
    let head = document.getElementsByTagName("head")[0];
    let script1 = document.createElement("script");
    script1.src = "https://accounts.google.com/gsi/client";
    script1.async = true;
    script1.defer = true;
    head.appendChild(script1);

    let script2 = document.createElement("script");
    script2.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', "${env.analyticsId}", 'auto');
        ga('send', 'pageview');
    `;
    head.appendChild(script2);

    let script3 = document.createElement("script");
    script3.src = "https://www.googletagmanager.com/gtag/js?id=${env.analyticsId}";
    script3.async = true;
    head.appendChild(script3);

    let script4 = document.createElement("script");
    script4.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', "${env.analyticsId}");
    `;
    head.appendChild(script4);

    let script5 = document.createElement("script");
    script5.src = "https://www.googletagmanager.com/gtag/js?id=G-CTPDMT0KJ2";
    script5.async = true;
    head.appendChild(script5);

    let script6 = document.createElement("script");
    script6.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-CTPDMT0KJ2');
    `;
    head.appendChild(script6);
}

function Botfunction(id, height, width, allow) {
    if (env.Node_env === "production") {
        console.log = function () {};
    }
    let clientConfig = {
        bot_id: id,
        style: {
            "max-height": height,
            "width": width,
        },
        allow: {
            'allow': allow,
        }
    };
    document.addEventListener("DOMContentLoaded", function () {
        ObjEmbeded.init(clientConfig);
        googleAnalyticsSetter();
    });
}

Botfunction('palmbot', '92vh', '100%', ['microphone']);