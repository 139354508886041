import { cookie } from "../general/cookie";
import { sandBox } from "../sharedService/sandBoxModule";
import { cookieExpire } from "../../env";
import { env } from "../../env";
import { LoadingModule } from "../general/loading";
import { sessionstorage } from "../general/sessionstorage";
import { contentRender } from "../sharedService/content-render";
import {renderPreviousConvo } from "./bootHelperFunctions";
import { decryptData } from "../general/encriptDecriptData";
import { getDeviceInfo } from "../general/getClientInfo";
import { ClientJS } from "clientjs";
import { FadeBackModal as ModalPop } from "../sharedService/modal-render";
import { googleAnalytics } from "../general/googleAnalytics";
import { httpService } from "../../../services/server.services";
import { privacyPolicy } from "../Module/privacyPolicyModule";
import { Boot } from "./boot";
import {encrypt, decrypt } from "../general/cryptoEncryptDecrypt";
import { uuid as uuidv1,isUuid } from 'uuidv4';
import localrundata from "../../../localDataJson/localrundata";
import { generalReplyModule } from "../Module/generalReplyModule";

let AgentTest= false

export var socketModule = {
  socket: null,
  livechat: false,
  branchSelected: null,
  llmFields:{},
  firstConnection:true,
  excludeRenderMsg: env.excludeRender,
  visited_link: '',
  visitorDetails: {},
  organization_name:{},
  shiftKeyRequired:false,
  init: async function (url, fieldToLLM, welcome_message) {

    this.socket = this.socket || io.connect(url, {
      query: {
        token: env.socket_token,
      },
    });
    this.llmFields=fieldToLLM
    this.organization_name={...fieldToLLM}
    this.visited_link = window.location.search;
    let web_link = new URLSearchParams(this.visited_link).get("link");
    let visitorCredentials = new URLSearchParams(this.visited_link).get("visitorCredentials");
    let organization= new URLSearchParams(this.visited_link).get("organization");
    this.organization_name.organization=organization;
    console.log(organization, "org>>>>")
    let origin = new URLSearchParams(this.visited_link).get("baseURL");
    env.parentUrl = origin

    if (visitorCredentials && visitorCredentials.length > 0) {
      visitorCredentials = decryptData(visitorCredentials) || '';
      try {
        visitorCredentials = JSON.parse(visitorCredentials) || {}
      } catch (error) {
        visitorCredentials = {}
      }
      this.visitorDetails = visitorCredentials
    }

    if (web_link) {
      web_link = decryptData(web_link)
    }
    this.visitorDetails = { ...this.visitorDetails, origin: origin , ...this.organization_name}
    let uniqueID = this.getUniqueId();
    this.uniqueID = uniqueID;
    this.socket.on("connect", async () => {
      console.log("hitttinconeect>>")
      this.socket.emit("user:join", this.uniqueID, "all", "User", env.botsource || 'web', this.visitorDetails, async (value,userData) => {
        if (!value) {
          return null;
        }
        console.log(value, "value>>", userData)
        if(value && value.hasOwnProperty('engagedWith')){
          $('#chatInitiate').replaceWith(`
            <svg id="chatEnd" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#32465ad4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-headphone-off" style="
                position: absolute;
                right: 50px;
                top: 22px;
                cursor: pointer;
            ">
            <path d="M21 14h-1.343"/>
            <path d="M9.128 3.47A9 9 0 0 1 21 12v3.343"/>
            <path d="m2 2 20 20"/>
            <path d="M20.414 20.414A2 2 0 0 1 19 21h-1a2 2 0 0 1-2-2v-3"/>
            <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 2.636-6.364"/>
            </svg>
        `);
          this.livechat=true
          let exitfn = function () {
            // renderPreviousConvo(this.uniqueID)
           generalReplyModule.init({text:"You are still interacting with a live agent"})
            return
          }
          let continueFN = function () {
            this.livechat = false
            // renderPreviousConvo(this.uniqueID)
            this.socket.emit("message:sent", {text:"menu", payload:"menu"}, { source: env.botsource || 'web'})
            return
          }
          const BODY_TEXT = `Do you want to end this livechat session with ${value.engagedWith}?`;
          ModalPop(continueFN, exitfn, BODY_TEXT, this)
          return
        }

        // if(Object.keys(userData).length===0){
        //   contentRender(localrundata.Lead)
        // }
        // renderPreviousConvo(this.uniqueID,value)
      
        this.deviceInfo = await getDeviceInfo();
        googleAnalytics.recordUrl({
          'web_link': web_link || env.parentUrl,
        })
        this.socket.emit("user:setData", {
          location: this.deviceInfo.location || null,
          latitude: this.deviceInfo.latitude || null,
          longitude: this.deviceInfo.longitude || null,
          browserInfo: this.deviceInfo.browserInfo || null,
          osInfo: this.deviceInfo.osInfo || null,
          newLink: web_link || env.parentUrl
        })
      });
      this.firstConnection &&  contentRender({
        title: `${welcome_message}`,
        type: 'quick_reply',
        branches: fieldToLLM.region_id ? await this.fetchBranch(fieldToLLM.region_id) : null,
        // branches: await this.fetchBranch(fieldToLLM.region_id) ,
        input: true,
        cookie: true,
        data: [
         
        ]
      })
      this.firstConnection=false
    });
    this.socket.on('disconnect', (reason) => {
      console.log('Disconnected:', reason);
      if (reason === 'transport error' || reason==="transport close" || reason === 'io server disconnect') {
        // Server initiated disconnect, need to manually reconnect
        console.log("reconnectinggggg................")
        this.socket.open(); 
      }
    });
    console.log("hittt>>>>")
  
  },

  getUniqueId: function () {
    let isForTestAgent=new URLSearchParams(this.visited_link).get("isForTestAgent") ;
    console.log(isForTestAgent, "testttt>>>>")
    console.log(this.visited_link,isForTestAgent,"visited link")
    if (isForTestAgent && !AgentTest) {
      let uniqueID_isForTestAgent = `${uuidv1()}_${Date.now()}`;
      cookie.setCookie("uniqueID", uniqueID_isForTestAgent, cookieExpire);
      AgentTest= true 
      return uniqueID_isForTestAgent;
  }
    if (this.uniqueID) {
      return this.uniqueID
    }
    let visitorId = new URLSearchParams(this.visited_link).get("visitorId");
    visitorId = visitorId ? decryptData(visitorId) : this.getNewCookie();
    if (visitorId) {
      cookie.setCookie("uniqueID", visitorId, cookieExpire);
      return visitorId
    }
    if (cookie.isCookieExists("uniqueID")) {
      if (cookie.getCookie("uniqueID") === visitorId) {
        return cookie.getCookie("uniqueID")
      } else {
        cookie.setCookie("uniqueID", visitorId, cookieExpire);
        return visitorId;
      }
    }
    const uniqueID = visitorId;
    cookie.setCookie("uniqueID", uniqueID, cookieExpire);
    return uniqueID;
  },
  checkconnection:async function (message, fieldToLLM) {
    console.log(this.branchSelected, "brancSelection:::")

    if(this.branchSelected){
      fieldToLLM.branchSelected = this.branchSelected
    }
    console.log(message, "msg>>>>", fieldToLLM)
    let status = true;
    if (status) {
      $("#autosuggest").html("");
      if (message === "menu") {
        // ---- here code for show menu
      }
      else {
        sandBox.showModules();
        sandBox.clearAllModules();
      }
      if (!this.livechat) {
        LoadingModule.init("#message-input-module");
      }

      // for socket message send
      if ($.trim(message) == "") {
        return false;
      }

      if(message.subtype){
        // this.renderMessage(message.text)
      }
      if(message.attachment && !message.forLivechat){
        this.renderMessage(message.attachment)
     
      }
      const title = typeof message === 'string' ? message : (message.text || message.title);
      const payload = message.payload || title;
      const attachment = message.attachment;

      if (payload === "human" && !this.livechat) {
        console.log("if>>>")
        this.renderMessage("Talk to live agent")
        this.socket.emit("message:sent", { text: 'Talk to Live Agent', payload: 'livechat:request:all' });
        LoadingModule.clear();
      }
      else if(this.livechat || message.subtype){
        console.log("elseif>>>")
        this.socket.emit("message:sent", { text: title, payload, attachment });
      }
       else {
        console.log("else>>>")
        // this.socket.emit("message:sent", { text: title, payload, attachment });
        let url = `${env.protocol}://${env.server}:${env.port}${env.basePath}llm/callLLM`
        const headers = {
          "content-type": "application/json",
        };
   
         let sender= this.getUniqueId()

        let response = await httpService(url, headers, "POST", {
          text: title,
          payload: payload,
          attachment: attachment,
          sender: sender,
          ...fieldToLLM
        });
        let responseData = await response.json()
        console.log(responseData, "resp>>>>")
        this.shiftKeyRequired=false
        $(".submit").removeAttr("disabled").css("cursor", "pointer");
        this.onMessageReceived(responseData, fieldToLLM)
      }
    } else {
      $.notify(env.internetConnection, "warn");
      return false;
    }
  },

  animationEnd:function(){
    $('#branchDropdown').addClass('fade-out');
      $('.select2-container').addClass('fade-out');
      $('.fade-out').on('animationend', function() {
        $('#branchDropdown').remove();
        $('.select2-container').remove();
      });
  },

  messageSend: function (message, fieldToLLM, attachment, prev = false) {
    console.log("inmessageSend>>", message)
    this.animationEnd();
    sandBox.clearAllModules()
    if ($(`#botinitialised .detailcontainer`)) {
      $(`#botinitialised .detailcontainer`).remove();
    }
    message = {
      type: "userMessage",
      text: message.text || message.title || message,
      payload: message.payload || message.data,
      attachment,
      forLivechat:socketModule.livechat,
      subtype:message.subtype || null
    };
    this.checkconnection(message, fieldToLLM);
    if (!prev) {
      sessionstorage.concateMessage("previousMessage", message);
    }
    $(".message-input textarea").val(null);

  },
  scrollBottom: function () {
    $(".messages").animate(
      {
        scrollTop: $("#message-module")[0].scrollHeight,
      },
      "fast"
    );
    console.log("triggereddd>>>>")
  },
  renderMessage: function (message) {
    console.log(message, "inrender>>>")
    const { type, text: title } = typeof message === 'string' ? { text: message } : message;
    if (type === "webProductDetails") {
      $(`<li class="replies" id="repliesID"><img id="repliesImg" src="${env.clientimg}"alt="" /><p id="repliesPara">
            <span><b>${message.title}</b></span><br/>
            <span>${message.brand}</span><br/>
            <span>${message.model}</span><br/>
            <span>${message.price}</span><br/><br />
            <img src="${message.image}" alt="image" style="width: 100%;margin:unset;border-radius:22px;" />
            </p></li>
          `).appendTo($(".message-section ul"));
      LoadingModule.clear();
    } else if (this.excludeRenderMsg.indexOf(message) < 0) {
      const urlRegex = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.,~#?&//=]*)$/g;
      let text;
      let link = message && message.payload ? message.payload.split(" ").join("%20") : message.split(" ").join("%20");
      console.log(link, "check link>>")
      switch ((message.attachment && message.attachment.type )||type) {
        case "image":
          text = `<a href=${link} target="_blank"><img src=${link} style="width: 100%;margin:unset;border-radius:22px;" /></a>`;
          break;
        // case "video":
        //   text = `<video controls src=${link} style="width: 100%; padding-right:14px;margin:unset;border-radius:22px;" />`;
        //   break;
        case "audio":
          text = `<audio controls src=${link} style="width: 100%;margin:unset;border-radius:22px;" />`;
          break;
        default:
          let messageText = message && message.payload ? message.payload : message;
          text = messageText.split(" ").map(piece => piece.match(urlRegex) ?
            `<a href=${piece} style="word-break:break-all;color:yellow;text-decoration:underline!important;" target="_blank">${piece}</a>` : piece).join(" ")
      }
      $(
        '<li class="replies" id="repliesID"><img id="repliesImg" src=' +
        env.clientimg +
        ` alt="" /><div class="connected-reply"><p id="repliesPara" ${message.type === 'audio' ? 'style="width:75% !important;"' : ''}>` +
        text +
        `</p></div></li>`
      ).appendTo($(".message-section ul:first"));
      setTimeout(() => {
        this.scrollBottom();
      }, 1000);
      $(".contact.active .preview").html("<span>You: </span>" + text);
    }
    let arabicTestRegex = /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF0-9\W\s]+$/;
    let isTextArabic = arabicTestRegex.test($(".replies #repliesPara").last().text());
    console.log($(".replies #repliesPara").last().text(),"arabic or not===>>>",isTextArabic)
    if (isTextArabic) {
      $(".replies #repliesPara").css({"direction":"rtl", 'text-align':"right"});
    } 

    $(".messages").animate(
      {
        scrollTop: $("#message-module")[0].scrollHeight,
      },
      "fast"
    );
  },


 async fetchBranch(region){
  console.log(region, "regg:::")
    let apiCallUrl= `${env.protocol}://${env.server}:${env.port}${env.basePath}ui/getBranch?region=${region}`;
    let response = await fetch(apiCallUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  
     let responseData = await response.json();
     console.log(responseData, "check>>>>>")
    return responseData
    
  },

  sideEffects(message) {
    const { type } = message;
    console.log(message, "side-effects>>")
    switch (type) {
      case 'livechatStarted':
        this.livechat = true;
        $('#chatInitiate').replaceWith(`
          <svg id="chatEnd" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#32465ad4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-headphone-off" style="
              position: absolute;
              right: 50px;
              top: 22px;
              cursor: pointer;
          ">
          <path d="M21 14h-1.343"/>
          <path d="M9.128 3.47A9 9 0 0 1 21 12v3.343"/>
          <path d="m2 2 20 20"/>
          <path d="M20.414 20.414A2 2 0 0 1 19 21h-1a2 2 0 0 1-2-2v-3"/>
          <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 2.636-6.364"/>
          </svg>
      `);
        break;
      case "livechatExpired":
        this.livechat = false;
        $('#chatEnd').replaceWith(`
          <svg id="chatInitiate" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#32465ad4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-headphones" style="
              position: absolute;
              right: 50px;
              top: 22px;
              cursor: pointer;
          ">
          <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 18 0v7a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3"/>
          </svg>
      `);
        break;
    }
  },

 async onMessageReceived(message, fieldLlm, metadata) {
  console.log(message, "msgreceiveddd>>>", {metadata, id:this.uniqueID})
    message.agent_Name =  metadata ? metadata.name : "Agent";
    message.hasOwnProperty('isform') ? $("#context").attr('disabled', 'true') : $("#context").removeAttr('disabled', 'true')
    if (metadata && metadata.sender && metadata.sender.toString() === this.uniqueID.toString()) {
      sandBox.clearAllModules()
    if (message.text && !message.formMessageSection && !message.attachment) {
        console.log("1")
        // this.renderMessage(message.text);
        return;

      }
    
    }
    if (message.attachment && message.type==="userMessage") {
      console.log("2")
      this.renderMessage(message.attachment);
      return;

    }
    try {
      if(message && message.hasOwnProperty('request_live_chat')){
        LoadingModule.clear();
        return this.socket.emit("message:sent",{text:"Live chat Request",payload:"livechat:request:sales" })
      
      }
      if (message.hasOwnProperty("prevUtter")) {
        if (message.prevUtter === false) {
          LoadingModule.clear(true);
        }
        if (message.prevUtter != false) {
          LoadingModule.clear();
        }
      }
      //  else {
      //   LoadingModule.clear();
      // }
      if(message.branchSelection){
        console.log(message, "msg:::::")
     let branches=  await this.fetchBranch(message.region)
     message.branches= branches
     message.llmFields = this.llmFields
      }
      console.log(message, "checkkkmessageee:::")
      if(message.getForm){
        setTimeout(async() => {
          let privacyPolicyStatus = await privacyPolicy.init(fieldLlm.country);
          console.log(privacyPolicyStatus, "<<<<privacyPolicyStatus");
          fieldLlm.gdpr = `${privacyPolicyStatus}`;
          if(privacyPolicyStatus){
            socketModule.messageSend("I accept it", fieldLlm)
          }
          else{
            socketModule.messageSend("I decline it", fieldLlm)
          }
          let localvalue = `${fieldLlm.branch_id || fieldLlm.region_id || fieldLlm.org_id}::${cookie.getCookie("uniqueID")}`
          let localEncrypt = encrypt(localvalue)
          localStorage.setItem(`privacyPolicyStatus_${localEncrypt}`, fieldLlm.gdpr);
          let Boots = new Boot();
          Boots.resetLlmFields(fieldLlm)
        }, 5000);
        
      }
      LoadingModule.clear()
      this.sideEffects(message)
      if(message.type !=="userMessage"){
        contentRender(message);
      }
    } catch (err) {
      console.log(err);
    }
  },

  getNewCookie: function () {
    // const client = new ClientJS();
    // let isCookie = client.isCookie();
    // if (isCookie && cookie.isCookieExists("webUniqueID")) {
    //   if (cookie.getCookie("webUniqueID")) {
    //     return cookie.getCookie("webUniqueID");
    //   }
    // }
    // let uniqueId = client.getFingerprint();
    // console.log(uniqueId, "cookie>>>");
    // return uniqueId

    const uuid = uuidv1(); 
    const timestamp = Date.now();

    const newUniqueID = `${uuid}_${timestamp}`;
    return newUniqueID
  }
};
