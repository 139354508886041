import { env } from "../../env";
import { socketModule } from "../core/socket";
import { cookie } from "../general/cookie";
import { PostInterest } from "../sharedService/postService";
import { leadValidationModule } from "./leadValidation";
// import { facebookModule } from "../oAuth/facebookModule";
import { googleModule } from "../oAuth/googleModule.js";


export var LeadCaptures = {
    data: "",
    init: function (data) {
        window.parent.postMessage(
            {
             "hideCross":true
            },
            env.parentUrl
          );
        $("#hideCrossBtn").css('display', 'none')
        console.log(data, "data>>>>>")
        this.data = data;
        this.cacheDOM();
        this.render();
        googleModule.init()

        // googleModule.renderBtn();
    },
    cacheDOM: function () {
        this.$frameContainer = $("#frame");
    },
    render: function () {
        this.mainContainer = $(
            '<div class="mainContainer" id="mainLeadPage"></div>'
        );
        this.mainContainer.appendTo(this.$frameContainer);
        let subConatiner = $('<div class="subContainer" id="subContainerId"></div>');
        subConatiner.appendTo(this.mainContainer);
        let img_div = $(
            '<div class="logo_wrapper"></div>'
        );
        let lead_heading_icon = $(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#32465ad4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lightbulb stroke-[0.8] w-7 h-7 mr-2 fill-primary/10"><path d="M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5"></path><path d="M9 18h6"></path><path d="M10 22h4"></path></svg>`)
        let lead_heading = $(
            `<div><p>Please share us your name and contact for follow-up.</p></div>`
        );
        lead_heading_icon.appendTo(img_div)
        lead_heading.appendTo(img_div);
        img_div.appendTo(subConatiner);
        // let paraDiv = $('<div id="paraDivId"></div>');
        // paraDiv.appendTo(subConatiner);
        // let para1 = $(`<span id="span1ID">${this.data.leadHeading}</span>`);
        // para1.appendTo(paraDiv)
        let formDiv = $('<div class="form_div" id="formDivID"></div>');
        formDiv.appendTo(subConatiner);
        let form = $('<form id="formID"> </form>');
        form.appendTo(formDiv);
        this.data.data.map((element) => {
            let input_div = $('<div class="form-group lead_Div"></div>');
        
            // Create a label for the input
            let label = $(`<label for="${element.id}" class="form-label">${element.label}</label>`);
            label.appendTo(input_div);
        
            // Create the input element
            let inputName = $(
                `<input autoComplete="off" size="30" class="inputss text-center"/>`
            );
            inputName.attr("placeholder", element.placeholder);
            inputName.attr("id", element.id);
            inputName.attr("type", element.type);
        
            if (element.id === "mobile_email") {
                inputName.attr("pattern", "/^\\+?\\d+$/");
            }
        
            inputName.appendTo(input_div);
            input_div.appendTo(form);
        });
        
        let continueBtn = $(
            `<button class="btn btn-colors" id="btn-designs">${this.data.button}</button>`
        );

        if (this.data.hasOwnProperty('button_skip')) {
            let skipAndContinue = $(`<p id="skipbtn">${this.data.button_skip}</p>`);
            skipAndContinue.appendTo(form);
            skipAndContinue.on("click", () => {
                // socketModule.messageSend("greet");
                this.clear();
            });
        }

        
        let socialContainer = $(`<div class="social-container"></div>`);
        let ProceedDiv = $(`<div></div>`)
        continueBtn.appendTo(ProceedDiv);
        let textSocial = $(`<div><p>OR</p></div>`)
        // let facebookIcon = $(
        //   `<div class = "facebook-icon-wrapper"><i class="fab fa-facebook-f"></i></div>`
        // );
        let googleIcon = $(`<div id="buttonDiv"><img src="images/menu/googleIcon.webp" style="width:25px;height:25px;"/></div>`);
        // let googleIcon = $(`<div id="buttonDiv"></div>`);
        googleIcon.on("click", ()=>{
            // googleModule.init()
        })

        // facebookIcon.on("click", facebookModule.login);
        // facebookIcon.appendTo(socialContainer);
        ProceedDiv.appendTo(socialContainer)
        textSocial.appendTo(socialContainer);
        googleIcon.appendTo(socialContainer);
        socialContainer.appendTo(form);

        // let span = $(`<div id="companyID">Developed By Palmmind</div>`);
        // span.appendTo(formDiv);
        // span.css({ cursor: "pointer" });
        let LastLine = $(`<div class="lastLine"></div>`);
        LastLine.appendTo(formDiv);
        continueBtn.on("click", (e) => {
            e.preventDefault();
            this.validation();
            let error = leadValidationModule.checkError();
            if (!error) {
                this.lead_capture();
                this.clear;
            }
        });


    },
    validation: function () {
        leadValidationModule.init(this.data);
    },
    lead_capture: async function () {
        let formData = {};
        this.data.data.map((ele) => {
            formData[ele.id] = $("#" + ele.id).val();
        });
        let visitorId = cookie.getCookie("uniqueID") || sessionStorage.getItem("uniqueID");
        let source = env.botsource;
        let url = this.data.action;
        let lead = {
            fullname: formData["fullname"],
            mobile: formData["mobile_email"],
            interest: "User interest through lead",
            visitorId: visitorId,
            source: source,
        };
        let res = await PostInterest(url, lead);

        if (res.status === "success") {
            socketModule.socket.emit("user:setData", {
                name: lead.fullname,
                mobile: lead.mobile
              })
            this.clear();
            // socketModule.messageSend("greet");
        } else {
            this.toastMessage(res);
        }
    },
    toastMessage: function (res) {
        let toggleParent = $("#subContainerId");
        let toastDiv = $(
            '<div aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center" id="Toast_messageCOntainer"></div>'
        ).appendTo(toggleParent);

        let toastClass = $(
            '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true"></div>'
        ).appendTo(toastDiv);
        toastClass.css({ opacity: "1", "background-color": "#DB0A0A" });
        $(`<div class="toast-body">${res.message}</div>`).appendTo(toastClass);
        setTimeout(() => {
            $("#Toast_messageCOntainer").remove();
        }, 3000);
    },
    clear: function () {
        window.parent.postMessage(
            {
             "hideCross":false
            },
            env.parentUrl
          );
        if (this.mainContainer) {
            this.mainContainer.remove();
        }
    },
};
