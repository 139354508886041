import { cookie } from "../js/general/cookie";
import { env, cookieExpire } from "../env";
import { decryptData, encryptData } from "../js/general/encriptDecriptData";
import { encrypt, decrypt } from "../js/general/cryptoEncryptDecrypt";
import { playSound } from "./notification";
import { uuid as uuidv1, isUuid } from 'uuidv4';
import { ClientJS } from "clientjs";

class Visitor {
  constructor($palmBot, $baseUrl, $appendButtonWebpage, embed, orgData, forTest) {
    console.log("enterrr>>>");
    this.assetURL = env.protocol + "://" + env.server + ":" + env.port;
    this.getUniqueId();
    this.baseUrl = $baseUrl;
    this.botEventUrl = $baseUrl;
    this.palmBot = $palmBot;
    this.orgData = orgData;
    this.forTest = forTest;
    this.showBot = false;
    this.botLoaded = false;
    this.notificationCount = 0;
    this.getEventFromBot();
    this.removeParamsFromURL = ["keys", "query", "botIframe", "removeByTag", "removeByClassName", "removeById"];
    this.appendButtonWebpage = $appendButtonWebpage;
    this.embed = embed;
  }

  sendEventToBot(iframeprop, roundBtn) {
    this.iframeprop = iframeprop;
    this.roundBtn = roundBtn;
  }

  async botVisibility(organization, usedField, queryParams) {
    try {
      let apiCallUrl = `${env.protocol}://${env.server}:${env.port}${env.basePath}ui/checkPlatformSettings?organization=${organization}&usedField=${usedField}&queryParams=${queryParams}`;
      let response = await fetch(apiCallUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      console.log(response, "botvisible>>>");
      let result = await response.json();
      console.log(result, "visibilityjsonresp>>>");
      if (!result.data) {
        return false;
      }
      return result.data.active;
    } catch (error) {
      console.log("Error in platform settings>>>>", error);
      return false;
    }
  }

  async loadMascotColor(organization, location, region) {
    let org;
    let loc;
    let reg;
    if (!organization) {
      let decodedOrgData = decodeURIComponent(this.orgData).replace(/ /g, "+");
      let decryptedOrgData = decrypt(decodedOrgData);
      console.log(decryptedOrgData, "checkdecryption>>>");
      let parsedOrgData = JSON.parse(decryptedOrgData);
      console.log(parsedOrgData, "checkk>>");
      org = parsedOrgData.client;
      loc = parsedOrgData.location || null;
      reg = parsedOrgData.region || null;

      let checkSettings = await this.botVisibility(org, loc ? loc : reg, loc ? 'branchId' : 'regionId');
      console.log(checkSettings, "checksett>>>");
      if (!checkSettings) {
        return false;
      }
    } else {
      let decodedOrg = decodeURIComponent(organization).replace(/ /g, "+");
      let decodedLocation = location ? decodeURIComponent(location).replace(/ /g, "+") : null;
      let decodedRegion = region ? decodeURIComponent(region).replace(/ /g, "+") : null;

      org = decrypt(decodedOrg);
      loc = decodedLocation ? decrypt(decodedLocation) : null;
      reg = decodedRegion ? decrypt(decodedRegion) : null;
    }

    console.log(org, "checkinvid++++", loc);

    let apiCallUrl = `${env.protocol}://${env.server}:${env.port}${env.basePath}ui/getOrganizationUi?organization=${org}`;

    if (loc) {
      apiCallUrl += `&branch=${loc}`;
    } else if (reg) {
      apiCallUrl += `&region=${reg}`;
    }

    console.log(apiCallUrl, "apiurl>>>");
    let response = await fetch(apiCallUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    console.log(response, "checkres>>>>>");
    let result = await response.json();
    console.log(result, "jsonresult>>>");
    let primColor = result.primaryColor;
    console.log(primColor, "checkinvid++++");
    document.documentElement.style.setProperty("--primary-color", primColor);
    let responseObj = {
      header_Logo: result.header_Logo,
      header_Name: result.header_Name,
      bot_Logo: result.bot_Logo,
      Welcome_Message: result.Welcome_Message,
      gdpr: result.gdpr,
      country: result.country,
      primaryColor: result.primaryColor,
      secondaryColor: result.secondaryColor,
      org_id: result.org_id,
      branch_id: result.branch_id,
      region_id: result.region_id
    };
    console.log(responseObj, "respObjvid>>>>>>>");
    return responseObj;
  }

  resetNotification() {
    this.notificationCount = 0;
    let badge = document.querySelector("#d1box .badge");
    let notificationEmbed = document.querySelector("#d1box .notificationEmbed");
    if (badge) badge.remove();
    if (notificationEmbed) notificationEmbed.remove();
  }

  getEventFromBot() {
    window.addEventListener("message", async (event) => {
      if (event.origin === this.botEventUrl) {
        let message = event.data;
        console.log(message, "checkmsg>>>>");
        if (message.hideCross) {
          document.getElementById("hideCrossBtn").style.display = "none";
        }
        if (!message.hideCross) {
          document.getElementById("hideCrossBtn").style.display = "inline";
        }
        if (!this.showBot) {
          this.notificationCount = !this.showBot && message.isCount ? this.notificationCount + 1 : 0;
          this.activeAgent = event.data.type !== "activeAgents" ? "" : event.data.data;
          if (this.activeAgent.length > 0) {
            let activeAgentContainer = document.getElementById("activeAgentContainer");
            if (activeAgentContainer) activeAgentContainer.remove();
            this.activeAgentContainer = document.createElement("div");
            this.activeAgentContainer.id = "activeAgentContainer";
            this.activeAgentContainer.innerHTML = `<p>Our agents are active now. Let's get connected <i style="font-size:24px" class="fa" id="connectBtn">&#xf0a9;</i></p>`;
            this.roundBtn.appendChild(this.activeAgentContainer);

            let crossBtn = document.getElementById("crossBtn");
            if (crossBtn) crossBtn.remove();
            this.crossButton = document.createElement("i");
            this.crossButton.className = "fa";
            this.crossButton.id = "crossBtn";
            this.crossButton.innerHTML = "&#xf00d;";
            this.activeAgentContainer.appendChild(this.crossButton);

            this.crossButton.addEventListener("click", (event) => {
              event.stopPropagation();
              this.crossButton.remove();
              this.activeAgentContainer.remove();
            });

            this.activeAgentContainer.addEventListener("click", () => {
              let requestAccept = {
                title: "Talk to Live Agent",
                payload: "livechat:request:all",
                type: "activeAgents",
              };
              this.iframeprop.contentWindow.postMessage(requestAccept, this.botEventUrl);
            });
          }
          if (this.notificationCount > 0) {
            playSound("get");
            let text =
              event.data.type !== "livechatIncomingRequest"
                ? ""
                : event.data.data.message
                ? event.data.data.message
                : event.data.data.text;
            let message =
              event.data.data.type !== "agentMessage"
                ? ""
                : event.data.data.attachment
                ? `${event.data.data.agent_Name ? event.data.data.agent_Name : "Live Agent"} sent you an attachment`
                : event.data.data.text;
            let intervene =
              event.data.data.type !== "livechatStarted"
                ? ""
                : event.data.data.message
                ? event.data.data.message
                : event.data.data.text;
            let agentName = event.data.type !== "livechatIncomingRequest" ? "" : event.data.data.agentName;
            if (
              event.data.type === "livechatIncomingRequest" ||
              event.data.data.type === "agentMessage" ||
              event.data.type === "livechatStarted"
            ) {
              let badge = document.querySelector("#d1box .badge");
              let notificationEmbed = document.querySelector("#d1box .notificationEmbed");
              if (badge) badge.remove();
              if (notificationEmbed) notificationEmbed.remove();

              this.notificationMessage = document.createElement("div");
              this.notificationMessage.className = "notificationEmbed";
              this.notificationMessage.innerHTML = `
                <div class="notificationEmbedMessage">
                  <p class="embedPara">${text || message || intervene}</p>
                </div>
                <div id="buttonDivConvo">
                  <button type="button" id="buttonConversation">${
                    text ? `Talk to ${agentName}` : message || intervene ? `Go to Conversation` : ""
                  }</button>
                </div>
                <div id="notificationReply">
                  <div id="inputReply">
                    <input type="text" placeholder="Reply..." />
                    <span class="embedSend"><i class="fa fa-paper-plane"></i></span>
                  </div>
                </div>`;
              this.roundBtn.appendChild(this.notificationMessage);
            }

            this.Notification = document.createElement("span");
            this.Notification.className = "badge";
            this.Notification.textContent = this.notificationCount;
            this.roundBtn.appendChild(this.Notification);
          }
          if (document.querySelector(".notificationEmbed")) {
            let crossBtn = document.getElementById("crossBtn");
            let activeAgentContainer = document.getElementById("activeAgentContainer");
            if (crossBtn) crossBtn.remove();
            if (activeAgentContainer) activeAgentContainer.remove();
          }
        }

        if (this.showBot || event.data.type === "livechatRejected" || event.data.type === "livechatExpired") {
          let badge = document.querySelector("#d1box .badge");
          let notificationEmbed = document.querySelector("#d1box .notificationEmbed");
          if (badge) badge.remove();
          if (notificationEmbed) notificationEmbed.remove();
        }

        let payload = event.data.type !== "livechatIncomingRequest" ? "" : event.data.data.buttons[0].payload;
        let title = event.data.type !== "livechatIncomingRequest" ? "" : event.data.data.buttons[0].title;
        if (title && payload) {
          document.querySelector(".notificationEmbed").addEventListener("click", () => {
            let requestAccept = {
              title: title,
              payload: payload,
              type: "livechatRequestAccept",
            };
            this.iframeprop.contentWindow.postMessage(requestAccept, this.botEventUrl);
          });
        }
      }
    });
  }

  webAppendButtoClicked() {
    let link = location.href;
    let requestAccept = {
      title: "Live chat request(Web Page)",
      payload: "livechat:request:all",
      type: "live-chat-request",
      visitor: this.getUniqueId(),
      link,
    };
    this.iframeprop.contentWindow.postMessage(requestAccept, this.botEventUrl);
  }

  async loadBot() {
    let iframe = this.palmBot.getElementsByTagName("iframe")[0];
    const encryptedVisitorId = encryptData(this.getUniqueId());
    const encryptedLink = encryptData(this.getLinkHistory());

    let search = window.location.search;
    let urlp = new URLSearchParams(search);
    let visitorCredentials = urlp.get("keys") || "";
    let orgParams = urlp.has("organization") ? urlp.get("organization") : null;
    let locationParams = urlp.has("location") ? urlp.get("location") : null;
    let regionParams = urlp.has("region") ? urlp.get("region") : null;
    let organization = orgParams;
    let location = locationParams;
    let region = regionParams;
    let detailsFetched = await this.loadMascotColor(organization, location, region);
    document.getElementById("palmbot").style.display = "block";
    if (!detailsFetched) {
      return false;
    }
    console.log(detailsFetched, "checkinvid++++");
    if (!iframe.src) {
      this.baseUrl = `${this.baseUrl}?visitorId=${encryptedVisitorId}&link=${encryptedLink}&baseURL=${window.location.origin}&hostname=${window.location.hostname}`;
      if (visitorCredentials && visitorCredentials.length > 0) {
        this.baseUrl = this.baseUrl + `&visitorCredentials=${urlp.get("keys")}`;
      }
      if (organization && organization.length > 0) {
        this.baseUrl = this.baseUrl + `&organization=${organization}`;
      }
      if (location && location.length > 0) {
        this.baseUrl = this.baseUrl + `&location=${location}`;
      }
      if (detailsFetched) {
        this.baseUrl = this.baseUrl + `&details=${encodeURIComponent(JSON.stringify(detailsFetched))}`;
      }
      console.log("checkTesttt>>>", this.forTest);
      if (new URLSearchParams(window.location.search).has("isForTestAgent") || this.forTest) {
        this.baseUrl = this.baseUrl + `&isForTestAgent=forTest`;
      }
      iframe.setAttribute("src", this.baseUrl);
    }
    this.gethistory();
  }

  getLinkHistory() {
    this.visitedUrl = window.location.href;
    let url = new URL(this.visitedUrl);
    for (let param of this.removeParamsFromURL) {
      url.searchParams.delete(param);
    }
    let newUrl = url.toString();
    this.visitedUrl = newUrl;
    return this.visitedUrl;
  }

  gethistory() {
    let lastUrl = location.href;
    new MutationObserver(() => {
      const url = location.href;
      if (url !== lastUrl) {
        lastUrl = url;
        this.onUrlChange();
      }
    }).observe(document, { subtree: true, childList: true });
  }

  onUrlChange() {
    let link = location.href;
    if (this.iframeprop && this.botEventUrl) {
      let timeFrame = setTimeout(
        function () {
          this.appendButtonWebpage();
          clearTimeout(timeFrame);
        }.bind(this.embed),
        2000
      );
      let url = new URL(link);
      for (let param of this.removeParamsFromURL) {
        url.searchParams.delete(param);
      }
      link = url.toString();
      this.iframeprop.contentWindow.postMessage({ link: link }, this.botEventUrl);
    }
  }

  getUniqueId() {
    let search = window.location.search;
    let urlp = new URLSearchParams(search);
    let isForTest = urlp.has("isForTestAgent") ? urlp.get("isForTestAgent") : null;
    console.log(isForTest, "iftesttt>>>");
    if (isForTest) {
      this.uniqueID = `${uuidv1()}_${Date.now()}`;
      cookie.setCookie("webUniqueID", this.uniqueID, cookieExpire);
      return this.uniqueID;
    }
    const client = new ClientJS();
    let isCookie = client.isCookie();
    const existingUniqueID = cookie.getCookie("webUniqueID");
    console.log("old id>", existingUniqueID);
    if (!existingUniqueID || (!isUuid(existingUniqueID.split('_')[0]))) {
      const uuid = uuidv1();
      const timestamp = Date.now();

      const newUniqueID = `${uuid}_${timestamp}`;

      if (isCookie) {
        cookie.setCookie("webUniqueID", newUniqueID, cookieExpire);
      }

      this.uniqueID = newUniqueID;
      console.log("new id>", this.uniqueID);
      return this.uniqueID;
    }
    if (existingUniqueID.split('_').length < 2 && isUuid(existingUniqueID)) {
      console.log("old id no timestamp", existingUniqueID);
      const timestamp = Date.now();
      const newUniqueID = `${existingUniqueID}_${timestamp}`;

      if (isCookie) {
        cookie.setCookie("webUniqueID", newUniqueID, cookieExpire);
      }

      this.uniqueID = newUniqueID;
      console.log("new id timestamp", this.uniqueID);
      return this.uniqueID;
    }
    this.uniqueID = existingUniqueID;
    console.log("already unique id", this.uniqueID);

    return this.uniqueID;
  }
}

export { Visitor };