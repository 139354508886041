export var countdown = {
    intervalId: null, // Store the interval ID
    endTime: null,    // Store the end time
    
    init: function() {
        this.cacheDOM();
        this.startCountdown();
    },
    
    cacheDOM: function() {
        this.$parent = $(`#message-module`);
        this.$counterDiv = $(`<div id="counterDiv"></div>`);
        this.$title = $(`<p>You will be connected to our live agents within :</p>`);
        this.$counter = $(`<p style="font-weight:600" id="counter"></p>`);
        this.$title.appendTo(this.$counterDiv);
        this.$counter.appendTo(this.$counterDiv);
        this.$parent.prepend(this.$counterDiv);
    },
    
    startCountdown: function() {
        // Clear any existing interval
        if (this.intervalId !== null) {
            clearInterval(this.intervalId);
        }

        // Set the end time to 3 minutes (180 seconds) from now
        this.endTime = Date.now() + 3 * 60 * 1000;

        const updateDisplay = () => {
            // Calculate remaining time
            const currentTime = Date.now();
            const remainingTime = Math.max(0, this.endTime - currentTime); // Prevent negative values
            const remainingSeconds = Math.floor(remainingTime / 1000);
            
            const mins = Math.floor(remainingSeconds / 60);
            const secs = remainingSeconds % 60;
            document.getElementById("counter").innerHTML = `${mins} minute${mins !== 1 ? 's' : ''} ${secs < 10 ? '0' : ''}${secs} second${secs !== 1 ? 's' : ''}`;
            
            // Check if time has run out
            if (remainingTime <= 0) {
                clearInterval(this.intervalId);
                this.intervalId = null;
                $("#counterDiv").remove();
            }
        };

        // Start the interval, recalculating based on the system time for accuracy
        this.intervalId = setInterval(updateDisplay, 1000);

        updateDisplay(); // Initial display
    },
    
    clear: function() {
        // Clear the interval if needed
        if (this.intervalId !== null) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
        if ($("#counterDiv")) {
            $("#counterDiv").remove();
        }
    }
};
