import { socketModule } from "../core/socket";
import { env } from "../../env";
import moment from "moment";
import Showdown from 'showdown';


export var generalReplyModule = {
  data: "",
  init: function (data) {
    if (typeof data === "object" && data !== null) {
      this.data = data.message || data.text || data.title ||  "";
      this.attachment = data.attachment || {}
    } else {
      this.data = data;
    }

    this.cacheDOM();
    this.render(data);
  },
  cacheDOM: function () {
    // this.$parentElem = $('#message-module .message-section ul');
    this.$parentElem = $("#message-module .message-section ul:first");
    this.dropDownContainer = $("#message-module .message-section")

  },
  scrollBottom: function () {
    $(".messages").animate(
      {
        scrollTop: $("#message-module")[0].scrollHeight,
      },
      "fast"
    );
  },
  render: function (data) {
    const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-color').trim();
    console.log(secondaryColor, "secondaryColor>>>>")
    console.log(JSON.stringify(data), ">>>>>>");
    let text = '';
    const useShowdown = new Showdown.Converter();
    text = useShowdown.makeHtml(data.text);

    const splitText = text.split(/\n+/);
    console.log(splitText, "split>>>", text)
    let lastSentence = '';

    if (splitText.length > 1) {
        lastSentence = splitText.pop(); 
        text = splitText.join('\n'); 
    }

    const urlRegex = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;

    text = text && text.split(" ").map(piece => piece.match(urlRegex) ?
      `<a href=${piece} style="word-break:break-all;color:blue;text-decoration:underline!important;" target="_blank">${piece}</a>` : piece).join(" ");


    this.renderElem = $(
      `<li class="sent"><img id="utterImage" src=${env.Orgimg} alt="" />`
    );
    if (text) {
      $(`<div class="connected">
            <div id="utterPara" style="margin-bottom:5px;">${text}</div>
          </div></li>`).appendTo(this.renderElem);
    } else {
      $(`</li>`).appendTo(this.renderElem);
    }

    this.attachElem = null;

    if (this.attachment && this.attachment.type === 'file') {
      this.attachElem = $(` <div class="connected">
        <svg height="22px" fill="${secondaryColor}" id="svg2" viewBox="0 0 32 32" width="22px" xmlns="http://www.w3.org/2000/svg">
          <path d="M30,22L16.001,8L2.001,22Z"></path>
        </svg>
        <div id="utterPara" style="margin-top:unset;">a href="${this.attachment.payload}" target="_blank">${this.attachment.payload.split("/").at(-1).split("-").at(-1)}</a></div>
      </div>`)
    } else if (this.attachment && this.attachment.type === 'audio') {
      this.attachElem = $(` <div class="connected">
            <svg height="22px" fill="${secondaryColor}" id="svg2" viewBox="0 0 32 32" width="22px" xmlns="http://www.w3.org/2000/svg">
              <path d="M30,22L16.001,8L2.001,22Z"></path>
            </svg>
            <div id="utterPara" style="margin-top:unset;width:100%;margin-bottom:5px"><audio controls src=${this.attachment.payload} style="width: 100%; padding-right:14px;margin:unset;border-radius:22px;" /></div>
          </div>`)
    } else if (this.attachment && this.attachment.type === 'video') {
      this.attachElem = $(` <div class="connected">
        <svg height="22px" fill="${secondaryColor}" id="svg2" viewBox="0 0 32 32" width="22px" xmlns="http://www.w3.org/2000/svg">
          <path d="M30,22L16.001,8L2.001,22Z"></path>
        </svg>
        <div id="utterPara" style="margin-top:unset;"><video controls src=${this.attachment.payload} style="width: 100%; padding-right:14px;margin:unset;border-radius:22px;" /></div>
      </div>`)
    } else if (this.attachment && this.attachment.type && this.attachment.type.length) {
      this.attachElem = this.attachment.type === 'image' ? $(`<p id="utterPara" style="margin-top:unset;"><a href="${this.attachment.payload}" target="_blank"><img src="${this.attachment.payload}" style="width:100%;height:100%;border-radius: 10px;" /></a></p>`) : this.attachment.type;

      this.attachElem =this.attachment.type === 'image' ?  $(` <div class="connected">
        <svg height="22px" fill="${secondaryColor}" id="svg2" viewBox="0 0 32 32" width="22px" xmlns="http://www.w3.org/2000/svg">
          <path d="M30,22L16.001,8L2.001,22Z"></path>
        </svg>
        <div id="utterPara" style="margin-top:unset;"><a href="${this.attachment.payload}" target="_blank"><img src="${this.attachment.payload}" style="width:100%;height:100%;border-radius: 10px;" /></a></div>
      </div>`) : this.attachment.type;
      
    }

    if (this.attachElem) {
      this.renderElem.append(this.attachElem);
    }

    this.renderElem.appendTo(this.$parentElem);

    let arabicTestRegex = /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF0-9\W\s]+$/;

    console.log(lastSentence, "lastsent>>")
    if (lastSentence && !lastSentence.match(/^<\/[a-z]+>$/)){
            const lastSentenceElem = $(
        `<li class="sent"><img id="utterImage" src=${env.Orgimg} alt="" />
          <div class="connected">
            <div id="utterPara" style="margin-bottom:5px;">${lastSentence}</div>
          </div>
        </li>`
      );
      lastSentenceElem.appendTo(this.$parentElem);

    }


    $(document).ready(function () {
      $(".connected a").attr("target", "_blank");
      $(".connected a").css({
        "color": "#FFD700",             
        "text-decoration": "underline",       
        "font-weight": "bold",           
        "cursor": "pointer",             
      });

    });
    function applyArabicStylingToElement(element) {
      let utterParaText = $(element).text();
      let isUtterParaArabic = arabicTestRegex.test(utterParaText);
  
      if (isUtterParaArabic) {
          console.log("Arabic text >>>>", utterParaText);
          $(element).css({"direction": "rtl", "text-align": "right"});
      }
  }
  
  // Apply styling to the last sentence
  $(".sent #utterPara").last().each(function() {
      applyArabicStylingToElement(this);
  });
  
  if (lastSentence) {
      $(".sent #utterPara").eq(-2).each(function() {
          applyArabicStylingToElement(this);
      });
  }
  
  data.branches && data.branches.length>0 && this.renderDropdown(data.branches, data)

  $('#message-module').removeAttr('data-select2-id');
  $(".message-section").removeAttr('data-select2-id')
    this.scrollBottom();
    $(".contact.active .preview").html("<span>You: </span>" + this.data);
    // socketModule.socket.emit('message', this.data);
  },

  renderDropdown: function(branches, data) {
    console.log(data, "drop>>>")
    $("#branchDropdown").remove();
    const $dropdown = $('<select></select>').attr('id', 'branchDropdown'); 
    $dropdown.append('<option value="" disabled selected>Choose a club</option>');
    branches.forEach(branch => {
      $dropdown.append(
        $('<option></option>')
          .val(branch.branchId)
          .text(branch.branchName)
      );
    });
    $(".sent #utterPara").last().attr("style", "margin-bottom: 5px !important");
    $dropdown.appendTo(this.dropDownContainer)
    $dropdown.select2({
      dropdownParent: this.dropDownContainer ,
       dropdownPosition: 'below'
    });

    $("#branchDropdown").on('select2:select', function(event) {
      // $('#branchDropdown').addClass('fade-out');
      // $('.select2-container').addClass('fade-out');
      socketModule.animationEnd();
      let selectedVal = event.params.data.id; 
      console.log(selectedVal, "selected:::");
      socketModule.branchSelected = selectedVal;
      socketModule.messageSend(data.query, data.llmFields);
  });
  
    

  }
  


};

