import { env, cookieExpire } from "../../env";
import jwt_decode from "jwt-decode";
import { cookie } from "../general/cookie";
import { PostInterest } from "../sharedService/postService";
import { sandBox } from "../sharedService/sandBoxModule";
import { socketModule } from "../core/socket";
export const googleModule = {
  init: function () {
    /**
     * @description Google login initialization
     */
    // Initialize Google login
    google.accounts.id.initialize({
      client_id: `${env.google_client_id}`,
      callback: this.handleCredentialResponse.bind(this),
    });

    // Render the actual Google button into the `#buttonDiv`
    google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
      theme: "outline", // Optional styling for the Google button
      size: "medium", // You can adjust the size if needed
    });

    // Optionally hide the custom button after rendering the actual Google button
    $("#customGoogleButton").hide();
    const googleButton = document.querySelector("#buttonDiv > div");
      if (googleButton) {
  googleButton.style.fontSize = "12px";
      }
  },

  // Decode response from Google login
  handleCredentialResponse: async function (response) {
    try {
      const { email, name, picture } = jwt_decode(response.credential);
      cookie.setCookie("userName", name, cookieExpire);
      sessionStorage.setItem("userName", name);

      let visitorId = cookie.getCookie("uniqueID");
      let source = env.botsource;
      let url = `${env.protocol}://${env.server}:${env.port}${env.basePath}user/userInterest`;

      let lead = {
        fullname: name,
        email: email,
        profile_pic: picture,
        visitorId: visitorId,
        source: source,
        interest: "Google Login",
      };

      PostInterest(url, lead);
      sandBox.clearAllModules();
      socketModule.messageSend("greet");
    } catch (error) {
      console.error("Error occurred:", error);
    }
  },
};