export var privacyPolicy = {
  init: function (country) {
    this.cacheDOM();
    return this.renderAndHandlePrivacyStatus(country);
  },
  cacheDOM: function () {
    this.$parentElem = $(".content"); 
  },
  renderAndHandlePrivacyStatus: function (country) {
    console.log("country:::::", country);
    return new Promise((resolve) => {
      let privacyPolicyContainer = $(
        `<div class="hidden" id="privacyContainer"></div>`
      );

      privacyPolicyContainer.load("privacy-policy.html", () => {
        // Replace 'UK' with the dynamic country
        $(".descriptive-text").first().html(function (_, oldHtml) {
          return oldHtml.replace("UK", country);
        });

        $("#Accept_btn").on("click", function () {
          $("#privacyContainer").remove();
          resolve(true);
        });

        $("#Reject_btn").on("click", function () {
          $("#privacyContainer").remove();
          resolve(false);
        });

        privacyPolicyContainer
          .removeClass("hidden") 
          .addClass("snap-animation");

        setTimeout(() => {
          privacyPolicyContainer.removeClass("snap-animation");
        }, 500);
      });

      privacyPolicyContainer.prependTo(this.$parentElem);
    });
  },
};
