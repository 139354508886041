import Vapi from '@vapi-ai/web';
import { env } from '../../env';
import { cookie } from '../general/cookie';
import {Howl} from 'howler';
// let respDashboard

let Sound
export const vapiMod = {
  endBtn:false,
    preinit:async function (organization, branch) {
      let sender= cookie.getCookie("uniqueID")
      console.log(sender, "senderrr>>>")
      let apiCallUrl = `${env.protocol}://${env.server}:${env.port}${env.basePath}call/getInitialSettings?organization=${organization}&branch=${branch}`;
      let response = await fetch(apiCallUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    
       let responseData = await response.json();
       console.log(responseData, "response data>>>>")
       let availableDays=[];
       for (const key of Object.keys(responseData.details.bookingTimeSlot)) {
        if (responseData.details.bookingTimeSlot[key]?.open) {
          availableDays.push(key);
        }
      }
      responseData.details.availableDays = availableDays;
      console.log(responseData, "response data>>>>")
       let finalApiCallUrl = `${env.protocol}://${env.server}:${env.port}${env.basePath}call/forFinalSettings`;

        let responseFinal = await fetch(finalApiCallUrl, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
           clientId:organization,
           locationId:branch,
           timezone:responseData.details.timezone,
           bookingSlots:responseData.details.bookingTimeSlot,
           multipleBooking:responseData.details.multipleBooking,
           slotDuration:responseData.details.slotDuration,
          }),
        });
        let responsefinalJson = await responseFinal.json();
        responseData.details.availableSlots=responsefinalJson
        responseData.details.sender=sender
      let promptgetUrl = `${env.protocol}://${env.server}:${env.port}${env.basePath}call/getPrompt?chatbotName=${responseData.chatbotName}&orgName=${responseData.orgName}&timezone=${responseData.details.timezone}`;
      let promptresp = await fetch(promptgetUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    
       let promptRespData = await promptresp.json();
       console.log(responseData, "response data>>>>")
        console.log(responseData, "final response in vapi>>>")
        return {responseData,promptRespData}
    },
    init:async function (org, branch) {
      this.endBtn=false;
        $('.backdrop').fadeIn();
        $('.calling-div').fadeIn();
        let SoundUrl=env.protocol=='https'? `${env.protocol}://${env.server}`:`${env.protocol}://${env.server}:${env.port}`;
       console.log(SoundUrl,"sound url")
        Sound = new Howl({
            src: [`${SoundUrl}/standardringtone.mp3`]
          });
          Sound.play();

          $(document).on('click', '#endCall, .end-btn', function () {
            $('.backdrop').fadeOut();
            $('.calling-div').fadeOut();
            Sound.stop();
            vapiMod.endBtn=true
            console.log("end call clicked", vapi)
            vapi && vapi.stop();
          })
        let setup= await this.preinit(org, branch)
          if(this.endBtn){
            console.log("call ended before start")
            return
          }
        const vapi = new Vapi(setup.responseData.assistandPrivateKey);
       
        this.eventListener(vapi)
     
        const assistantOverrides = {
          variableValues: setup.responseData,
          model: {
            provider: "openai",
            model: "gpt-4o-mini",
            messages: [
              {
                role: "system",
                content: setup.promptRespData.prompt,
              },
            ],
            tools: setup.promptRespData.tools,
          },
        };
        let assistantId=setup.responseData.assistantId
        let assistant={firstMessage	:	"Hello! How can I help you today?",
          firstMessageMode	:	"assistant-speaks-first",
          assistantId
          };
        console.log({
          assistantId: setup.responseData.assistantId,
          assistant,
          assistantOverrides
        }, "vapicredddd>>>>>")
      
        vapi.start(
          // {

          assistantId,
          // assistant,
        // },
          assistantOverrides,
        );

        // vapi.start(`c1e74007-ac67-4728-a910-54279df08f26`)
    },
    eventListener:function (vapi) {
        vapi.on('speech-start', () => {
            console.log('Speech has started');
          });

        vapi.on('call-start', () => {
            console.log('Call has started');
            $('.calling-div h2').text("Call Initiated");
            $('.calling-div p').text("You are connected now. Please start speaking");
            Sound.stop();
            
          });
         $(document).on('click', '#endCall, .end-btn', function () {
        $('.backdrop').fadeOut();
        $('.calling-div').fadeOut();
        Sound.stop();
        vapiMod.stopConnection(vapi)
        $('.calling-div h2').text("Calling...");
        $('.calling-div p').text("We're trying to connect you. Please wait.");
    });
    },
    stopConnection:function (vapi) {
        vapi.stop();
    }

}
