
import { env } from "../../env";

const CryptoJS = require("crypto-js");

const encrypt = (raw) => {
  const secretKey = env.SECRET_ENCRYPTION_KEY;
  const encrypted = CryptoJS.AES.encrypt(raw, secretKey).toString();
  return encrypted;
};

const decrypt = (encrypted) => {
  try {
    if(!encrypted){
      return null;
    }
    console.log(encrypted, "encrypt>>>");
    const secretKey = env.SECRET_ENCRYPTION_KEY;
    const bytes = CryptoJS.AES.decrypt(encrypted, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  } catch (error) {
    console.log("Error in decryption===>",error);
  }
 
};

export { encrypt, decrypt };
